html {
  max-width: 100vw;
  overflow-x: hidden;
}

@font-face {
  font-family: "Classic";
  src: local("FLClassic"),
    url("./fonts/FootLocker-FLClassic.otf") format("opentype");
}

@font-face {
  font-family: "Standard";
  src: local("FLStandard"),
    url("./fonts/FootLocker-FLStandard.otf") format("opentype");
}

body {
  margin: 0;
  font-family: "Classic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  html {
    max-width: 100vw;
    overflow-x: hidden;
  }
  body {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .App {
    max-width: 100vw;
    overflow-x: hidden;
  }
}
